@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
[data-baseweb="button"] {
    background-color: var(--black) !important;
}
:root {
    --gray: #e3e5e8;
    --black: #131c25;
    --dimmed: #8f9397;
    --dimmed2: #909498;
    --rootColor: #f3f4f7;
    --lineColor: #e5e7ec;
    --inputColor: #ced2da;
    --inputFocusColor: #33435a;
}
* {
    color: var(--black);
    font-family: "Pretendard" !important;
}
#root,
#root > div:first-child {
    height: 100%;
}
html,
body {
    
    height: 100%;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Noto Sans KR", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div[data-baseweb="popover"] {
    z-index: 100;
}

ol[data-baseweb="progress-steps"] > li > div:first-child {
    background-color: transparent;
}

#root > div:nth-child(2) > div:nth-child(2) > div {
    z-index: 50;
}

#content .echChart.echChart--column > div.echLegend.echLegend--horizontal.echLegend--left.echLegend--top > div > ul.echLegendList {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)) !important;
}

/* react-step-progress */
._2Jtxm._2ZUAI ._2JvrO {
    background-color: var(--black) !important;
}
._2Jtxm._35Ago > ._2kL0S {
    background-color: var(--black) !important;
}
._2Jtxm::before {
    height: 1.5px;
    background-image: linear-gradient(to left, var(--lineColor) 50%, var(--black) 50%) !important;
}
._53Ji7 {
    padding: 2rem 0;
}
/* / react-step-progress */

/* Milkdown */
.milkdown {
    border-radius: 15px !important;
    box-shadow: unset !important;
    border: 1px solid #dddbda;
}
.milkdown .editor {
    padding: 0 1.25em 0 !important;
}
.milkdown .editor:focus-visible {
    outline: 1px solid #000;
    border-radius: 10px;
}
.milkdown .editor > * {
    margin: 0 !important;
}
.milkdown .editor > p {
    line-height: 1.5 !important;
    font-size: 14px !important;
}
/* Milkdown */

/* Timeline */
[data-baseweb="progress-steps"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 1rem !important;
}
[data-baseweb="progress-steps"] div:has(> [data-baseweb="button"]) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
[data-baseweb="progress-steps"] [data-baseweb="button-group"] {
    justify-content: flex-end !important;
    margin-left: auto;
    margin-right: 0 !important;
    max-width: 128px;
}
[data-baseweb="tabs"] {
    touch-action: pan-y;
}
[data-baseweb="table-semantic"] th,
[data-baseweb="table-semantic"] td {
    padding: 12px;
}
[role="tablist"] {
    position: relative;
    background-color: #fff !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    gap: 20px;
}
[role="tablist"]::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 6px;
    width: calc(100% - 12px);
    height: 2px;
    background-color: var(--lineColor);
    z-index: 0;
    opacity: 0.6;
}
[role="tablist"] [role="tab"] {
    color: var(--dimmed) !important;
    position: relative;
    outline-offset: 0;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8px !important;
    text-align: center;
    z-index: 1;
    min-width: 80px !important;
    font-size: 16px;
}
[role="tablist"] [role="tab"][aria-selected="true"] {
    color: var(--black) !important;
}
@media screen and (max-width: 768px) {
    [role="tabpanel"][aria-labelledby="0"], [role="tablist"] {
        /* justify-content: space-between; */
        gap: 12px;
    }
    [role="tablist"] [role="tab"] {
        /* min-width: unset !important; */
        white-space: nowrap;
        min-width: 52px !important;
        font-size: 14px !important;
    }
}
[role="tablist"] [role="tab"][aria-selected="true"] {
    color: #000;
    font-weight: 600;
}
[role="tabpanel"] [data-baseweb="block"] {
    gap: 0.5rem;
}
[role="tabpanel"] > [data-baseweb="block"] {
    padding-top: 80px !important;
    padding-bottom: 80px;
}
[role="tabpanel"][aria-labelledby="0"]
[role="tabpanel"][aria-labelledby="1"],
[role="tabpanel"][aria-labelledby="2"] {
    min-width: max-content;
}
[role="tabpanel"][aria-labelledby="0"] > [data-baseweb="block"] {
    padding-top: clamp(80px, 16vw, 80px) !important;
}
/* [role="tabpanel"][aria-labelledby="0"] [data-baseweb="block"] > div,
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="block"] > div,
[role="tabpanel"][aria-labelledby="2"] [data-baseweb="block"] > div,
[role="tabpanel"][aria-labelledby="3"] [data-baseweb="block"] > div {
    padding-top: 0 !important;
} */
@media screen and (min-width: 1024px) {
    [role="tabpanel"][aria-labelledby="0"] > [data-baseweb="block"] {
        padding-top: 80px !important;
    }
}
#content {
    margin-top: 40px !important;
    padding-top: 0 !important;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--rootColor);
}
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="tag"] span {
    max-width: unset;
}
@media screen and (max-width: 768px) {
    [role="tabpanel"] [data-baseweb="block"] {
        margin-left: unset;
        margin-right: 0 !important;
        gap: 0.5rem;
    }

    #content {
        padding: 20px;
    }
    [data-baseweb="table-semantic"] th,
    [data-baseweb="table-semantic"] td {
        padding: 10px;
    }
    [role="tabpanel"] {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    [role="tabpanel"][aria-labelledby="0"],
    [role="tablist"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    [data-baseweb="button-group"] {
        margin-right: 30px !important;
    }
    [role="tabpanel"][aria-labelledby="1"] [data-baseweb="tag"] span {
        max-width: 100px;
    }
}

@media screen and (max-width: 500px) {
    [data-baseweb="progress-steps"] [data-baseweb="button-group"] {
        max-width: 128px;
        margin-right: calc(20px + 0.5rem) !important;
    }
}
/* Timeline */

/* paper */

.row-count {
    font-size: 12px;
    transition: color 0.15s ease-out;
    color: #666;
}
p:has(>.row-count) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
div:has(>.flex-row) {
    margin-left: 10px;
    width: calc(100% - 57px);
}
#project-row {
    padding-top: 4px;
}
#project-row .flex-row {
    position: relative;
    width: 100%;
}
#project-row > div > [data-baseweb="tag"] {
    transform: translateY(-5px);
}
@media screen and (max-width: 768px) {
    #project-row > div > [data-baseweb="tag"] {
        transform: translateY(-1px);
    }
}
#project-row .flex-row.row-date {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 113px;
    min-width: 113px;
}
.flex-row 
.flex-row a {
    /* color: #666; */
    color: var(--black) !important;
    line-height: 1.4;
    cursor: pointer;
    transition: color 0.15s ease-out;
    background: none !important;
    line-height: 24px;
    margin-top: 2px;
}
.flex-row span {
    color: var(--dimmed2) !important;
    font-size: 14px !important;
}
.flex-row.row-date {
    color: var(--dimmed2) !important;
    font-size: 14px !important;
    line-height: 20px;
}
.flex-row [data-baseweb="link"] {
    cursor: pointer;
}
.flex-row [data-baseweb="link"],
.flex-row span.row-count,
.link-template p {
    color: var(--black) !important;
    transition: color 0.15s ease-out;
    background: none;
}
[data-baseweb="block"]:has(> .link-template) {
    /* margin-top: 38px !important; */
}
.link-template {
    display: block;
    line-height: 24px;
}
.link-template:first-of-type {
   
}
.link-template li {
    width: 100%;
}
.link-template li div {
    width: 100%;
}
.link-template li p {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    box-sizing: border-box;
}
.link-template li > div {
    min-height: unset;
    padding: 18px 0px;
}
.link-template p {
    font-size: 16px !important;
    line-height: 24px;
}
@media (hover: hover) {
    .link-template p:hover {
        text-decoration: underline;
    }
    .flex-row a:hover {
        text-decoration: underline;
    }
}
.flex-row div p + p {
    padding-top: 0 !important;
}
.flex-row {
    padding: 0 6px;
}
.row-date {
    margin-top: auto;
    margin-left: auto;
    min-width: max-content;
    padding: 1px 0 1px 1.5rem;
    box-sizing: content-box;
    font-size: 12px;
    text-align: right;
    line-height: 2;
}
.row-count {
    min-width: 22px;
}
.request-description {
    padding: 6px 0 0 !important;
    line-height: 1.6 !important;
    white-space: pre-line;
    word-break: keep-all;
}
.tag-wrap {
    padding-top: 6px;
    margin-left: -5px !important;
}
[data-baseweb="typo-headingxsmall"] {
    margin: 12px 0.5rem 1rem !important;
}
[data-baseweb="typo-labelsmall"] {
    padding-left: 0 !important;
}
[data-baseweb="typo-labelxsmall"] {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #999 !important;
}
[role="tabpanel"],
[role="tabpanel"][aria-labelledby="0"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
@media screen and (max-width: 768px) {
    [data-baseweb="tag"] {
        height: 26px !important;
    }
    .flex-row,
    .flex-row a,
    .flex-row a + span {
        font-size: 14px;
    }

    .flex-row span {
        font-size: 12px;
    }
    .row-date {
        font-size: 12px;
    }
    .milkdown .editor {
        padding: 1rem 20px 0 !important;
        max-height: 180px;
        overflow: auto;
    }
    [data-baseweb="typo-headingxsmall"] {
        font-size: 28px !important;
    }
}

.attachment-badge {
    display: inline-block;
    padding: 2px 0;
    width: 18px;
    height: 18px;
    margin: 0 0 0 4px;
    background-color: var(--black);
    color: #fff;
    text-align: center;
    line-height: 13px;
    vertical-align: bottom;
    font-size: 11px;
    font-weight: 700;
    border-radius: 50%;
    box-sizing: border-box;
    text-indent: -1.5px;
    letter-spacing: -1.5px;
}
body::-webkit-scrollbar {
    display: none;
}
.tooltip-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 2;
}
.tooltip-text span {
    display: block;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .tooltip-text {
        font-size: 12px;
    }
    .tootip-text span {
        padding-top: 0.3rem;
        line-height: 1.5;
    }
}
.noti-list {
    padding: 1rem 1.5rem;
    box-sizing: border-box;
}
svg[title="Menu"] {
    display: none !important;
}
@media screen and (max-width: 1136px) {
    svg[title="Menu"] {
        display: block !important;
    }
}
[title="Menu"] rect {
    width: 18px;
    height: 1px;
}

[title="Right"] path {
    fill: #000;
}
.is-changing {
    margin-top: 0.7rem;
    margin-bottom: 1.2rem;
}
.is-changing [data-baseweb="input"] {
    width: calc(75% - 2rem);
}
.is-changing [data-baseweb="block"] {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}
.is-changing [data-baseweb="button"] {
    max-height: 32px;
}

body {
    overflow: hidden;
}

.client-ip {
    color: #919191 !important;
    font-size: 12px !important;
    padding-top: 3.2rem !important;
}
[role="gridcell"] {
    border-radius: 50%;
    overflow: hidden;
}
[role="gridcell"]::after {
    display: none !important;
}

.file-container button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px !important;
    font-size: 12px;
    padding: 10px;
    color: #000;
    background-color: #eee;
}
.file-container button::after {
    display: block;
    content: "";
    background-image: url(../public/assets/icon-delete.svg);
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
}
.file-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% - 92px);
    gap: 6px;
}
.file-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    align-items: center;
    padding: 10px;
    background-color: #eee;
}
.file-container [data-baseweb="tag"] {
    margin: 0 !important;
}
.button-file {
    position: relative;
    width: 80px;
    margin-bottom: auto;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    border-radius: 0;
    border: none;
    outline: none;
    background-color: #000;
    color: #fff;
    font-weight: 500;
    border-radius: 30px;
}
.button-file::before {
    content: "파일 첨부";
}
.button-file input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
}
/* input[name="attachments"]::before {
    content: "파일 선택";
} */

textarea {
    height: 20vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
}

.milkdown {
    border-radius: 5px !important;
    border-color: var(--gray) !important;
}
.milkdown .editor:focus-visible {
    border-radius: 5px !important;
}
#comment-list {
    display: flex;
    flex-direction: column;
    gap: 0;
}
#comment-list .comment:first-of-type {
    border-top: 1px solid var(--gray) !important;
}
#comment-list .comment {
    position: relative;
    padding: 1rem 0;
    margin: 0;
    border-bottom: 1px solid var(--gray) !important;
}
#comment-list .comment .milkdown {
    width: calc(100% - 80px);
    border: none !important;
    background-color: transparent;
    margin-left: unset;
}
#comment-list .comment .comment-name {
    padding-left: 8px;
}
#comment-list .comment .comment-name + div {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 28px;
    top: 1rem;
    right: 0;
    line-height: 22px;
    width: 80px;
    text-align: right;
    font-size: 12px !important;
}
#floating-ui {
    position: fixed;
    /* width: 100vw; */
    /* padding: 0 10px; */
    width: calc(100vw - 7.6rem - 248px);
    max-width: calc(1300px - 7.6rem + 48px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    bottom: 30px;
    gap: 0.5rem;
    box-sizing: border-box;
    pointer-events: none;
    left: 0;
    margin-left: calc(260px + 3.8rem);
    right: 0;
    z-index: 10;
    /* background-color: pink; */
    /* margin: auto; */
}
@media screen and (max-width: 1136px) {
    #floating-ui {
        width: calc(100vw - 7.6rem + 54px);
        max-width: unset;
        margin-left: 3.8rem;
    }
}
@media screen and (max-width: 768px) {
    #floating-ui {
        width: calc(100vw - 40px + 44px);
        max-width: calc(100vw - 44px);
        margin-left: 20px;
    }
}
#floating-ui button {
    min-height: 40px;
    width: 40px;
    min-width: 40px;
    background-color: var(--black) !important;
    border-radius: 50% !important;
    pointer-events: auto;
}
#floating-ui button path {
    stroke-width: 1 !important;
}
.g2 {
    box-shadow: none !important;
}
.gl:has([data-baseweb="tag"]) {
    margin-left: 0;
}
[data-baseweb="button"] {
    border-radius: 5px !important;
}
[role="tabpanel"], [role="tabpanel"][aria-labelledby="0"],
[role="tabpanel"] {
    padding-left: 40px !important;
    padding-right: 40px !important;
}
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="typo-labelsmall"],
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="button"] {
    font-size: 16px;
    margin-top: -2px;
    margin-bottom: 20px;
    line-height: 16px !important;
}
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="button"] {
    background-color: #fff !important;
}
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="tag"] {
    margin-top: -2px !important;
    border-color: var(--black) !important;
}
[role="tabpanel"]:has([data-baseweb="table-semantic"]) thead tr:first-of-type th:first-of-type {
    padding-left: 0 !important;
}

[role="tabpanel"]:has([data-baseweb="table-semantic"]) tbody tr td:first-of-type {
    padding-left: 0 !important;
}
[data-baseweb="pagination"] {
    padding-top: 20px;
}
[data-baseweb="pagination"] [data-baseweb="select"]:has([data-baseweb="icon"]) {
    padding-right: 0;
    max-width: 80px;
    width: 80px;
    border: none !important;
    outline: none !important;
}
[data-baseweb="pagination"] [data-baseweb="select"] * {
    outline: none !important;
    padding-top: 0;
    padding-bottom: 0;

}
[data-baseweb="pagination"] [data-baseweb="select"] > div {
    min-height: 32px;
}
[data-baseweb="pagination"] [data-baseweb="select"] div[value] {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: max-content;
    min-width: max-content;
}
[data-baseweb="pagination"] [aria-hidden="true"] {
    margin-left: 0;
    margin-right: 30px;
}
[data-baseweb="pagination"] [role="listbox"] {
    /* width: 0; */
}
.sidebar-tabs > div {
    position: relative;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 0;
    background-color: transparent;
}
.sidebar-tabs > div div {
    color: var(--dimmed) !important;
    transition: color 0.15s ease-out;
}
.sidebar-tabs > div[data-active="true"] {
    font-weight: 500;
}
.sidebar-tabs > div[data-active="true"] div {
    color: var(--black) !important;
}
@media (hover: hover) {
    .sidebar-tabs > div:hover div {
        color: var(--black) !important;
    }
}
.sidebar-tabs > div > div {
    margin-left: 0;
}
.sidebar-tabs {
    margin-top: -22px;
    width: calc(100% - 16px);
    margin-left: auto;
    margin-right: auto;
}
@media (hover: hover) {
    .sidebar-tabs > div:hover {
        background-color: transparent;
    }
}
.sidebar-tabs > div::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    content: '';
    background-color: var(--rootColor);
    width: calc(100% - 20px);
    height: 100%;
    z-index: -1;
    border-radius: 8px;
    opacity: 0;
}
.sidebar-tabs > div[data-active="true"]::before {
    opacity: 1;
}
div:has(> [data-baseweb="select"]) {
    outline: none !important;
    
}
#content [data-baseweb="block"] {
    box-shadow: none !important;
    padding-left: 0 ;
    padding-right: 0;
    border-radius: 0;
}
[data-baseweb="typo-headingxsmall"] {
    margin-left: 0 !important;
}
div:has(> [data-baseweb="tag"]) {
    margin-left: 0 !important;
    margin-bottom: 20px;
}
#button-add-project + div > div + li {
    border-top: none !important;
}
#pagination-ui {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    white-space: nowrap;
    padding-bottom: 40px;
    transform: translateY(15px);
    background-color: transparent;
}
#pagination-ui > [data-baseweb="block"] {
    padding-bottom: 0;
    max-height: 36px;
    margin-left: 0;
}
#pagination-ui > [data-baseweb="block"] > [data-baseweb="button"] {
    padding-left: 10px;
    padding-right: 10px;
    max-height: 36px;
}
#pagination-ui [data-baseweb="input"] {
    position: relative;
    border: 1px solid var(--inputColor);
}
#pagination-ui [data-baseweb="input"] svg path {
    fill: var(--inputColor);
}
#pagination-ui [data-baseweb="input"] > div {
    background-color: #fff;
}
[data-baseweb="base-input"] {
    background-color: #fff !important;
}
[data-baseweb="base-input"] input {
    background-color: transparent;
}
#array-filter {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-left: auto;
    align-items: center;
    margin-right: -8px;
}
#array-filter [data-baseweb="block"] {
    padding-bottom: 0 !important;
    margin: 0 !important;
}
#array-filter [data-baseweb="block"] svg {
    transition: transform 0.1s ease-out;
}
#array-filter [data-baseweb="block"] svg path {
    fill: var(--dimmed);
    transition: fill 0.1s ease-out;
}
#array-filter [data-baseweb="block"] [data-baseweb="button"] {
    color: var(--dimmed);
    transition: color 0.1s ease-out;
}
@media (hover: hover) {
    #array-filter [data-baseweb="block"] [data-baseweb="button"]:hover {
        background-color: #fff !important;
    }
}
#array-filter [data-baseweb="block"] [data-baseweb="button"][aria-expanded="true"] {
    color: var(--black);
}
#array-filter [data-baseweb="block"] [data-baseweb="button"][aria-expanded="true"] svg {
    transform: scaleY(-1);
}
#array-filter [data-baseweb="block"] [data-baseweb="button"][aria-expanded="true"] svg path {
    fill: var(--black);
}
#array-filter  [data-baseweb="block"] > [data-baseweb="button"] {
    padding: 8px;
    background-color: #fff !important;
}
#pagination-ui [data-baseweb="input"] {
    max-width: 240px;
    
}
#pagination-ui div:has(>[title="Search"]) {
    order: 3
}
div[data-baseweb="popover"] {
    /* left: -210px; */
    /* max-width: 240px; */
    border-radius: 5px !important;
    overflow: hidden;
}
[aria-label="dialog"] {
    border-radius: 5px !important;
}
[data-baseweb="progress-steps"] div:has(>.check-button) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-100%);
}

[data-baseweb="progress-steps"]:has(.check-button) [role="tabpanel"][data-baseweb="block"] {
    padding-top: 40px;
}
[data-baseweb="card"] {
    background: #fff;
    border-width: 1.5px !important;
    border-style: dashed !important;
    border-color: #dfdfdf;
}
[data-baseweb="file-uploader"] > [tabindex="-1"] {
    position: relative;
    background: #fff;
    border: 1.5px dashed #dfdfdf !important;
    transition: border-color 0.15s ease-out;
}
[data-baseweb="file-uploader"] > [tabindex="-1"]> [data-baseweb="button"] {
    font-size: 0;
}
[data-baseweb="file-uploader"] > [tabindex="-1"]> [data-baseweb="button"]::before {
    font-size: 14px;
    content: 'Browse file'
}
[data-baseweb="file-uploader"] > [tabindex="-1"]  > div:first-of-type {
    font-size: 0;
    color: #c1c1c1 !important;
}
[data-baseweb="file-uploader"] > [tabindex="-1"]  > div:first-of-type::before {
    font-size: 14px;
    content: 'Drop & Drop file here';
    color: #c1c1c1 !important;
    transition: color 0.15s ease-out;
}

.is-dragging [data-baseweb="file-uploader"] > [tabindex="-1"] {
    border-color: var(--black) !important;
}
.is-dragging [data-baseweb="file-uploader"] > [tabindex="-1"]  > div:first-of-type::before {
    color: var(--black) !important;
}
#table-log th:last-of-type,
#table-log td:last-of-type {
    width: 140px;
}
#header {
    height: 0;
    padding: 0;
}
@media screen and (max-width: 1136px) {

    #header {
        padding: 30px 20px 0;
    }
}
[data-baseweb="block"]:has([data-baseweb="typo-headingxsmall"]>#title-header) {
    position: relative;
    align-content: flex-start;
}
[data-baseweb="block"]:has([data-baseweb="typo-headingxsmall"]>#title-header) #button-edit {
    margin-top: 16px;
}
.text-total {
    color: var(--dimmed2);
}
#title-header {
    margin-bottom: 5px !important;
    font-size: 22px;
    padding-right: 5px !important;
    min-height: 29px;
    gap: 12px !important
}
#title-header [data-baseweb="tag"] {
    /* margin-top: -1px !important; */
    transform: scale(1.1) translateY(-3px) !important;
    transform-origin: 0% 50%;
    transform-origin: top left;
    min-width: 46px;
    margin-bottom: auto;
}
#project-row:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

#button-edit {
    color: var(--dimmed) !important;
    font-size: 14px !important;
    cursor: pointer;
    min-width: max-content;
}

[data-baseweb="tag"]:has([title="진행"]),
[data-baseweb="tag"]:has([title="접수"]),
[data-baseweb="tag"]:has([title="거절"]),
[data-baseweb="tag"]:has([title="대기"]),
[data-baseweb="tag"]:has([title="종료"]),
[data-baseweb="tag"]:has([title="계약"]) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    height: 24px !important;
    font-size: 12px;
    font-weight: 700 !important;
    line-height: 14px;
    
}
[data-baseweb="tag"]:has([title="접수"]) span[title],
[data-baseweb="tag"]:has([title="진행"]) span[title],
[data-baseweb="tag"]:has([title="거절"]) span[title],
[data-baseweb="tag"]:has([title="대기"]) span[title],
[data-baseweb="tag"]:has([title="종료"]) span[title],
[data-baseweb="tag"]:has([title="계약"]) span[title] {
    color: inherit;
    /* transform: translateY(0.5px); */
}

body:has(#button-edit) [data-baseweb="tag"]:has([title="접수"]),
body:has(#button-edit) [data-baseweb="tag"]:has([title="진행"]),
body:has(#button-edit) [data-baseweb="tag"]:has([title="거절"]),
body:has(#button-edit) [data-baseweb="tag"]:has([title="대기"]),
body:has(#button-edit) [data-baseweb="tag"]:has([title="종료"])
body:has(#button-edit) [data-baseweb="tag"]:has([title="계약"]) {
    transform: scale(1.1);
    transform-origin: top left;
    min-width: 46px;
    margin-bottom: auto;
    /* opacity: 0.5; */
}
[data-baseweb="textarea"],
[data-baseweb="input"] {
    border: 1px solid var(--inputColor) !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    overflow: hidden !important;
    transition: border-color 0.1s ease-out;
}
[data-baseweb="input"]:focus-within,
[data-baseweb="textarea"]:focus-within {
    border-color: var(--inputFocusColor) !important;
}
[data-baseweb="base-input"] {
    border: none !important;
}
[data-baseweb="input"] input,
[data-baseweb="base-input"] input,
[data-baseweb="textarea"] textarea {
    background-color: transparent!important;
}
div:has(>#buttons) {
    width: max-content !important;
    margin-left: unset;
    margin-right: 0.5rem;
}
div:has(>#buttons):last-of-type {
    margin-right: 0;
}
div:has(>#buttons):not(:has(button)) {
    width: 0;
}
div:has(> div:has(>#buttons)) {
    margin-right: 0 !important;
}
#buttons-wrap {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    transform: translate(-18px, -0.5rem);
    margin-right: 0 !important;
}
#buttons-wrap #buttons {
    padding-left: 18px !important;
    padding-right: 18px !important;
}
div:has(> #header) {
    background-color: var(--rootColor);
}
[data-baseweb="typo-labelxsmall"] {
    color: var(--black) !important;
}
[data-baseweb="typo-labelsmall"] {
    color: var(--black) !important;
}
.tag-wrap  {
    color: unset;
}
.tag-wrap span[title] {
    color: inherit
}
#heading6 * {
    /* color: var(--black) !important; */
}
.count {
    color: var(--black) !important;
}
.sidebar-tabs + div > div {
    color: var(--dimmed) !important;
    padding-left: 10px;
}
[data-baseweb="base-input"] input {
    color: var(--inputFocusColor) !important;
}
[data-baseweb="base-input"] input {
    caret-color: green !important;
}
::placeholder {
    color: var(--inputColor) !important;
}
input:focus::placeholder {
    color: var(--inputFocusColor) !important;
}
[data-baseweb="table-semantic"] th, [data-baseweb="table-semantic"] td {
    border-color: var(--lineColor) !important; 
}
[data-baseweb="tag"]:has([title="대기"]) span[title] {
    color: #000 !important;
}
#client-row {
    width: 100%;
    gap: 10px;
    margin-bottom: 50px;
}
.created-time {
    font-size: 14px;
    font-weight: 500 !important;
    color: var(--dimmed);
}
.client-name {
    padding-left: 62.5px;
    font-size: 16px;
    font-weight: 500 !important;
    color: var(--dimmed);
}
[role="tabpanel"][aria-labelledby="2"]:has(.empty-text) > div {
    width: 100%;
    display: block;
    padding: 60px 20px !important;
    border: 1px solid var(--lineColor);
    /* margin-top: calc(2rem + 12px) !important; */
    margin-bottom: 60px;
    margin-top: 80px;
    border-radius: 5px !important;
    overflow: hidden;
    box-sizing: border-box;

}
[role="tabpanel"][aria-labelledby="1"] > div {
    /* margin-top: 12px!important; */
}
.empty-text {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--dimmed);
}
[data-baseweb="tag"]:has([title="거절"]),
[data-baseweb="tag"]:has([title="대기"]) span[title] {
    color: #000 !important
}
.check-button,
.check-button + button,
.check-button + button + button {
    width: 120px !important; 
    height: 48px !important;
    font-size: 14px !important;
    border-radius: 12px !important;
    overflow: hidden;
    white-space: nowrap;
    border: 1.5px solid var(--black) !important;
    background-color: #fff !important;
    color: var(--black) !important;
    transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
@media (hover: hover) {
    .check-button:hover {
        background-color: var(--black) !important;
        color: #fff !important;
    }
}
.check-button.selected,
.check-button + button.selected,
.check-button + button + button.selected {
    background-color: var(--black) !important;
    color: #fff !important;
}
.message {
    margin-bottom: 60px;
    font-size: 13px;
    margin-top: -14px;
}

#milkdown-editor {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
#milkdown-editor button {
    font-size: 14px;
    padding-right: 20px !important;
    background-color: transparent !important;
    color: var(--dimmed);
}
[data-baseweb="block"]:has(> div > .milkdown) {
    position: relative;
}
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="typo-labelxsmall"] {
    color: var(--dimmed) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
[data-baseweb="typo-labelsmall"]:has(.comment-name) {
    display: flex;
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 21px;
    box-sizing: border-box;
}

[data-baseweb="drawer"] [data-baseweb="typo-labelxsmall"] {
    color: var(--dimmed) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
[data-baseweb="drawer"] [data-baseweb="typo-labellarge"] {
    font-size: 24px !important;
    margin-top: 34px;
    margin-bottom: 20px;
}
[data-baseweb="drawer"] [data-baseweb="typo-labelsmall"] {
    font-size: 16px;
    margin-top: -2px;
    margin-bottom: 20px;
    line-height: 16px !important;
}
[data-baseweb="drawer"] > div:nth-of-type(2) > div {
    padding-left: 18px !important;
    padding-right: 18px !important;

}
[role="tabpanel"] [data-baseweb="block"] div:has(> [data-baseweb="tag"]) {
    margin-bottom: 0 !important;
}

#heading-container >[data-baseweb="typo-headingxsmall"] {
    margin-right: 0 !important;
}
[role="option"]:has([value="completed"]) {
    display: none;
}

[data-baseweb="pagination"] [data-baseweb="button"]:has([data-baseweb="icon"]) {
    font-size: 0 !important;
    background-color: #fff !important;
}[data-baseweb="pagination"] [data-baseweb="button"]:has([data-baseweb="icon"]):disabled path {
    fill: var(--dimmed) !important;
}
[data-baseweb="pagination"] [data-baseweb="button"]:has([data-baseweb="icon"]) > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

[data-baseweb="pagination"] [title="open"] {
    transform: translateX(6px);
    transition: transform 0.1s ease-out;
}
[data-baseweb="pagination"] [data-baseweb="select"][aria-expanded="true"] [title="open"] {
    transform: translateX(6px) scaleY(-1) !important;
}

[role="option"] {
    background-color: hsl(0, 0%, 100%) !important;
}
[role="option"] [data-baseweb="checkbox"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translateY(-2px);
}
[data-baseweb="checkbox"] > span:first-of-type {
    position: relative;
    background-color: transparent !important;
    filter: invert(1);
    background-size: 10px 10px;
    border: 1px solid rgba(255,255,255,0.3) !important;
    transition: border-color 0.1s ease-out;
    background-image: none !important;
}
[data-baseweb="checkbox"] > span:first-of-type::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-size: 12px 12px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%2217%22%20height%3D%2213%22%20viewBox%3D%220%200%2017%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6.50002%2012.6L0.400024%206.60002L2.60002%204.40002L6.50002%208.40002L13.9%200.900024L16.1%203.10002L6.50002%2012.6Z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20") !important;
    opacity: 0;
    transition: opacity 0.1s ease-out;
}
[role="option"]:has([aria-checked="true"]) [data-baseweb="checkbox"] > span:first-of-type {
    border-color: #fff !important; 
}
[role="option"]:has([aria-checked="true"]) [data-baseweb="checkbox"] > span:first-of-type::before {
    opacity: 1;
}
[role="option"]:has([aria-checked="mix"]) [data-baseweb="checkbox"] > span:first-of-type,
[role="option"]:has([aria-checked="false"]) [data-baseweb="checkbox"] > span:first-of-type {
    border: 1px solid rgba(255,255,255,0.3) !important;
}
[role="option"]:has([aria-checked="mix"]) [data-baseweb="checkbox"] > span:first-of-type::before
[role="option"]:has([aria-checked="false"]) [data-baseweb="checkbox"] > span:first-of-type::before {

    opacity: 0 !important;
}
[data-baseweb="checkbox"] > span + input + div {
    transform: translateY(4px);
    padding-left: 10px;
}
[role="navigation"] > div:nth-of-type(3) {
    transition: none !important;
}
.client-ip {
    padding-top: 100px !important;
}
@media (hover: hover) {

    [data-baseweb="typo-labelxsmall"] + [data-baseweb="button"]:hover {
        background-color: #fff !important;
    }
}

#template-form label {
    padding-left: 5px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--dimmed)
}
#buttons-wrap #buttons {
    background-color: var(--black) !important;
}
#template-form #buttons.button-delete {
    background-color: #cd1b18 !important;
}
#buttons-wrap {
    
}
#buttons-wrap:has(.button-delete) {
    /* margin-right: -18px; */
}
[data-baseweb="form-control-caption"] li {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--dimmed);
    width: calc(100% - 10px);
    margin-left: 5px;
}

#modal-confirm {

}
#flex-buttons {
    gap: 10px;

}

#flex-buttons button {
    border-radius: 5px;
    background-color: var(--black);
    color: #fff;
}
#flex-buttons button:first-of-type {
    background-color: #cd1b18 !important;
}
[data-baseweb="modal"]:has(#flex-buttons) [role="dialog"]:first-of-type {
    min-height: 260px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
[data-baseweb="modal"]:has(#flex-buttons)  .title {
    display: block;
    font-size: 16px !important;
    margin-top: 20px !important;
}
#button-comment-edit {
    top: -3px;
}
#button-comment-edit button {
    background-color: #fff !important;
}
button {
    outline-color: transparent !important;
}
[data-baseweb="modal"]:has(#modal-emailform) {

}
[data-baseweb="modal"]:has(#modal-emailform) > div > div {
    max-height: 92vh;
    overflow: auto;
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="textarea"],
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="textarea"] textarea {
    resize: none;
    max-height: 300px;
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="select"]{
    border: 1px solid var(--inputColor) !important;
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="select"][aria-expanded="true"] {
    border-color: var(--inputFocusColor) !important;
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="select"] * {
    font-size: 14px;
    background-color: transparent !important;
    
}
[data-baseweb="modal"]:has(#modal-emailform) input,
[data-baseweb="modal"]:has(#modal-emailform) textarea {
    font-size: 14px !important;
}
[data-baseweb="modal"]:has(#modal-emailform) button {
    background-color: var(--black) !important;
    color: #fff !important;
    font-size: 14px !important;
    max-height: 36px;
}
[data-baseweb="modal"]:has(#modal-emailform) button:first-of-type {
    background-color: #fff !important;
    color: var(--black) !important;
    border-color: var(--black);
}
[data-baseweb="modal"]:has(#modal-emailform) button:last-of-type {
    background-color: #00a23d !important
}

[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="select"] {
    border-radius: 5px;
    border-width: 1px !important;
    overflow: hidden;
    outline: none !important;
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="select"] > div {
    border: none !important;
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="form-control-label"] {
    padding-left: 5px;
    padding-right: 10px;
    margin-top: 0 !important;
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--dimmed);
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="form-control-caption"] ul {
    padding-left: 15px !important;

}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="form-control-container"] {
    margin-bottom: 0 !important;
}
[data-baseweb="modal"]:has(#modal-emailform) [data-baseweb="form-control-caption"] {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
    color: var(--dimmed);
    width: calc(100% - 10px);
    margin-left: 5px;
}
@media screen and (min-width: 1136px) {

    div:has(> .sidebar-tabs) {
        transition: none !important;
    }
}
#modal-emailform + div {
    padding-bottom: 20px !important;
}

#heading-container {
    margin-top: 24px !important;
}
[data-baseweb="modal"]:has(#create-request) button {
    max-height: 36px !important;
    height: 36px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 14px !important;
}
[data-baseweb="modal"]:has(#create-request) #button-prev {
    background-color: #fff !important;
    border: 1px solid var(--black) !important;
    color: var(--black) !important;
}
[data-baseweb="modal"]:has(#create-request) [data-baseweb="base-input"] input {
    background-color: #fff !important;
}

[data-baseweb="checkbox"]:has(input[type="checkbox"]) > span {
    border-radius: 5px !important;
}
[data-baseweb="checkbox"]:has(input[type="checkbox"][aria-checked="true"]) > span::before {
    opacity: 1;
}
[data-baseweb="checkbox"]:has(input[type="checkbox"][aria-checked="true"]) > span {
    border: 1px solid #fff !important;
}
[data-baseweb="select"] > div {
    background-color: #fff !important;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid var(--inputColor) !important;
}

[data-baseweb="select"][aria-expanded="true"] > div {
    border-color: var(--inputFocusColor) !important;
}

[data-baseweb="modal"]:has(#create-request) [role="panel"]:nth-of-type(4) [data-baseweb="tag"] {
    background-color: #fff;
    border: 1.5px solid var(--black);
    font-size: 14px !important;
    font-weight: 500 !important;
    min-width: 40px;
    height: 27px;
    max-height: 27px;
    padding: 4px 12px !important;
    margin-right: 5px;
    margin-left: 0 !important;
}
[data-baseweb="modal"]:has(#create-request) [role="panel"]:nth-of-type(4) [data-baseweb="tag"]:last-of-type {
    margin-right: 0 !important;
}
[data-baseweb="modal"]:has(#create-request) [data-baseweb="form-control-label"] {
    color: var(--dimmed);
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 10px;
}

[data-baseweb="modal"]:has(#create-request) [data-baseweb="form-control-caption"] {
    color: var(--dimmed);
    font-size: 12px;
    margin-top: 4px;
}

[data-baseweb="modal"]:has(#create-request) > div > [tabindex="-1"] {
    max-height: 90vh;
    min-height: 70vh;
    overflow-y: auto;
    margin: auto;
    width: 800px;
    max-width: calc(100% - 40px);
}
[data-baseweb="modal"]:has(#create-request) > div > [tabindex="-1"] li span + div {
    white-space: nowrap;
}
[data-baseweb="modal"]:has(#create-request) > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
[aria-label="Calendar."] [role="gridcell"][tabindex="0"] {
    background-color: var(--black) !important;
}
@media (hover: hover) {
    [aria-label="Calendar."] [role="gridcell"]:hover {
        background-color: var(--black) !important;
    }
    [aria-label="Calendar."] [role="gridcell"]:hover > div {
        color: #fff !important;
    }
}
[aria-label="Calendar."] [role="gridcell"][tabindex="0"] > div,
[aria-label="Calendar."] [role="gridcell"][aria-label^="Selected."] > div {
    color: #fff !important
}
[data-baseweb="select"]:has([data-baseweb="icon"]) [data-baseweb="icon"] path {
    fill: var(--inputColor)
}
[data-baseweb="select"]:has([data-baseweb="icon"]) [data-baseweb="icon"] {
    transition: transform 0.15s ease-out;
}
[data-baseweb="select"]:has([data-baseweb="icon"])[aria-expanded="true"] [data-baseweb="icon"] {
    transform: scaleY(-1);
}
[data-baseweb="select"]:has([data-baseweb="icon"])[aria-expanded="true"] [data-baseweb="icon"] path {
    fill: var(--black);

}
[data-baseweb="select"]:has([data-baseweb="icon"]) [data-baseweb="icon"][title="Delete"] svg {
    transform: none !important;
}
[data-baseweb="select"]:has([data-baseweb="icon"]) [data-baseweb="icon"][title="Delete"] path {
    fill: var(--black);
}

.noti-list + [data-baseweb="button"] {
    background-color: #fff !important;
    color: var(--black) !important;
    font-size: 0;
    padding: 14px 10px !important;
    border-top: 1px solid var(--lineColor);
}
.noti-list + [data-baseweb="button"]::before {
    content: '더 읽어오기';
    font-size: 14px;
}
[title="Clear value"] {
    transform: none !important;
}

.error-message * {
    color: #fff !important;
}
[role="tabpanel"][aria-labelledby="1"] [data-baseweb="typo-labelsmall"].request-description {
    line-height: 1.4 !important;
}
[role="tabpanel"][aria-labelledby="1"] > div {
    width: 100%;
}
[role="tabpanel"][aria-labelledby="1"] > div > div {
    /* min-width: max-content; */
}
.flex-row {
    width: calc(100% - 57px);
}
.client-row-name {
    display: inline-block;
    -webkit-line-clamp: 1;
    max-width: calc(100% - 123px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    line-height: 22px;
}
@media screen and (max-width: 768px) {
    #base-block {
        padding: 0 20px !important;
        min-width: 260px;
    }
    [role="tabpanel"], [role="tabpanel"][aria-labelledby="0"], [role="tabpanel"] {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .milkdown .editor {
        padding-left: 8px !important;
    }
    #button-comment-edit {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(calc((-100% - 0.25rem)))
    }
    .created-time {
        padding-left: 62.5px;
    }
    #client-row {
        flex-direction: column;
        align-items: flex-start;
    }
    #header-content {
        padding-top: 20px !important;
        padding-bottom: 0 !important;
        min-height: max-content;
        padding-bottom: 8px !important;
    }
    #title-header {
        font-size: 18px;
    }
    #button-blocks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);   
    }
    #button-blocks button {
        width: auto !important;
        min-width: 70px;
    }
    #base-block > [data-baseweb="block"] {
        overflow-x: auto;
    }
    .flex-row span.row-count {
        margin-bottom: auto;
    }

}
button:focus,
button:focus-visible {
    outline-color: transparent !important
}
:focus-visible {
    outline-color: transparent !important;
    border: none !important;
    outline: none !important;
}
#logo {
    width: 110px;
    height: 23px;
    background-image: url(../public/assets/logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

[role="alert"] * {
    color: #fff !important
}
[title="계약"] {
    color: #fff !important;
}

/* .badge-status {
    position: relative;
    width: 7vw;
    min-width: 60px;
    max-width: 70px;
} */
/* .badge-status::before {
    position: relative;
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.8%;
    background-image: url(../public/assets/badge-new.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.--cancelled::before {
    background-image: url(../public/assets/badge-cancelled.png);
}
.--aligning::before {
    background-image: url(../public/assets/badge-aligning.png);
}
.--completed::before {
    background-image: url(../public/assets/badge-completed.png);
} */
#button-back {
    /* width: 190px !important; */
    /* background-color: #b41541 !important;
    border-color: #b41541 !important; */
    /* color: #fff !important; */
}
[role="tabpanel"][aria-labelledby="0"] [data-baseweb="tag"] {
    transform: scale(1.2) !important;
}
[role="tabpanel"][aria-labelledby="0"] [data-baseweb="tag"]:has([title="종료"]),
[role="tabpanel"][aria-labelledby="0"] [data-baseweb="tag"]:has([title="계약"]) {
    transform-origin: top left;
    min-width: 46px;
    margin-bottom: auto;
}
[data-baseweb="block"]:has(> #milkdown-editor) .milkdown {
    min-height: 96px;
    padding: 1rem 0;
}
[data-baseweb="block"]:has(> #milkdown-editor) .milkdown .paragraph {

}
[data-baseweb="block"]:has(> #milkdown-editor) .milkdown .paragraph + .paragraph {
    min-height: unset;
    padding-top: 0;
}
[data-baseweb="block"]:has(> #milkdown-editor) .milkdown .paragraph:has(.link) {
    /* display: none; */
}
[data-baseweb="block"]:has(> #milkdown-editor) .milkdown .paragraph:last-of-type {
    padding-bottom: 1rem;
}
[data-baseweb="block"]:has(> #milkdown-editor) .milkdown .paragraph .hide,
[data-baseweb="block"]:has(> #milkdown-editor) .milkdown .paragraph a.link {
    display: none;
}
[data-baseweb="block"]:has(> #milkdown-editor) #blah {
    background-color: blue;
    appearance: none;
    display: none;
}

.input-file-label {
    pointer-events: auto;
    cursor: pointer;
    font-size: 14px;
    color: var(--dimmed);
}

[data-baseweb="block"]:has(> #milkdown-editor):has(a.link) .input-file-label {
    color: var(--black);
}
.file-badge-container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: calc(100% - 2rem);
    padding: 1rem 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    pointer-events: none;
}
.file-badge {
    font-size: 10px;
    padding: 0 6px;
    border: 1px solid #000;
    border-radius: 20px;
    background-color: #fff;
    height: max-content;
}
#milkdown-editor {
    bottom: auto;
    top: 1rem !important;
}
[data-type="emoji"] {
    position: relative;
}
img:empty {
    position: relative;
    width: 0;
    overflow: hidden;
    font-size: 0;
}
img:empty::before {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(alt);
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    font-size: 10px;
  }
  [data-baseweb="tag"]:has([title="미사용:완료"]) {
    background-color: #000 !important;
    border-color: #000 !important;

    opacity: 0.3 !important;
    
  }
  [data-baseweb="tag"]:has([title="미사용:완료"]) span {
    color: #fff !important;
  }
  [data-baseweb="tag"]:has([title="계약"]) {
    background-color: #1972E7 !important;
    border-color: #1972E7 !important;
  }

  .tooltip-input:has(input[placeholder="Input Image Link"]),
  .tooltip-input:has(input[placeholder="Input Web Link"]) {
    display: none !important;
  }

  [data-baseweb="tag"]:has([title="종료"]) span[title] {
    color: #000 !important;
  }
  [data-baseweb="tag"]:has([title="거절"]) span[title] {
    color: #fff !important;
  }